import React from "react"
import LayoutAsset from "../../../components/LayoutAsset"
import { Link } from "gatsby"

export const AssetInfo = {
   title:"Cute Boar",
   description: "A cute asset packs with models, animations and more",
   link:"/Assets/UnityArt/CuteBoar" ,
   boxImage:"Assets/UnityArt/CuteBoar/_Box.png",
   bannerImage:"Assets/UnityArt/CuteBoar/_Banner.png",
   primaryButton: { 
      label: "BUY",
      href: "https://assetstore.unity.com/packages/slug/175449?aid=1101liAaC&pubref=website&utm_source=aff"
   },
   /*demos: {
    itchio: "#" 
   },*/
   images: [
       {
       original: 'Assets/UnityArt/CuteBoar/_Box.png',
       thumbnail: 'Assets/UnityArt/CuteBoar/_Box.png',
       description: 'Cute boars!',
       originalAlt : 'Cute Boars',
       thumbnailAlt: 'Cute Boars',
       },
       {
       original: 'Assets/UnityArt/CuteBoar/ScreenShot_Sleeping.PNG',
       thumbnail: 'Assets/UnityArt/CuteBoar/ScreenShot_Sleeping.PNG',
       description: 'Contains effects synced with the animations',
       originalAlt : 'Sleeping boars',
       thumbnailAlt: 'Sleeping boars',
       },
       {
       original: 'Assets/UnityArt/CuteBoar/Models.png',
       thumbnail: 'Assets/UnityArt/CuteBoar/Models.png',
       description: 'Props and models',
       originalAlt : 'Props and models',
       thumbnailAlt: 'Props and models',
       },
    ],
   //documentationLink:"/Documentation/CuteBoar",
   //forumLink:"/#",
   videosLink:"https://www.youtube.com/watch?v=sl8Y0J-Q9m8&feature=youtu.be"
}

export default () => (
  <LayoutAsset  assetInfo={AssetInfo}>
     <h1>Cute Boar Pack</h1>
     <br/>

     The Cute Boar Pack is an art asset which contains models, animations, shaders and props.<br/><br/>

      <strong>Content:</strong><br/>
      - 3 Boar Rigged Models: Normal, Derpy, Strong.<br/>
      - 8 Animations: Idle, Walk, Run, Sleep, Attack, Eat, Damaged, Death.<br/>
      - 4 Grass textures.<br/>
      - Props: 2 Nuts, Apple, Mushroom, Rock, 2 Boar Meat, Roast Boar, 2 Tree trunks.<br/>
      - 4 Custom Shaders: Toon lighting, Grass, Matcap, Outline. <br/>
      <br/>

      <strong>Requirements:</strong><br/>
      - Built-in Render pipeline or URP<br/><br/>

      <strong>Limitations:</strong><br/>
      - Does not support HDRP
      - URP shaders only contain a custom shader for the grass, the other use the default unlit shader. 
      <br/><br/>

      All custom shaders were created in Amplify Shader for the built-in render pipeline.
  </LayoutAsset>
)